// Desktop only, for boxed layout
@media only screen and (min-width: $l_screen_md_min) {
    .layout-boxed {
        > .full-height {
            max-width: $l_layout_boxed_max_width;
            margin: auto;
            box-shadow: 0 0 1px rgba(0,0,0,.2);

            .app-sidebar {
                left: auto;
            }


            .app-header {
                // in boxed mode, .app-header position is the same, only style of .app-header-inner is changed
                > .app-header-inner {
                    max-width: $l_layout_boxed_max_width;
                    margin: auto;
                    box-shadow: 0 1px 1px rgba(0,0,0,.2); // Note: it overrides default $header_box_shadow
                }
            }

            .app-overlay {
                > .app-overlay-inner {
                    max-width: $l_layout_boxed_max_width;
                    margin: auto;
                }
            }
        }
    }
}