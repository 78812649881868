.app-overlay {
    .app-overlay-inner {
        max-width: 1090px;
        margin: 0 auto;
        padding: 20px 30px;
        @media only screen and (min-width: $screen-sm-min) {
            padding: 20px 100px;
        }
    }

    input.overlay-search-input {
        border: 0;
        background-color: transparent;
        font-size: 35px;
        font-weight: normal;
        width: 100%;
        padding-left: 0;
        line-height: 1;
        @media only screen and (min-width: $screen-sm-min) {
            font-size: 70px;
        }

        &:focus {
            outline: none;
        }
    }

    .overlay-header {
        position: relative;
        padding: 60px 0 0;

        h2 {
            font-size: 18px;
            font-weight: normal;
            margin: 0;
            @media only screen and (min-width: $screen-sm-min) {
                font-size: 24px;
            }
        }
    }

    a.overlay-close {
        position: absolute;
        top: 0;
        right: 10px;
        font-weight: $font-weight-thin;

        .material-icons {
            font-size: 32px;
        }
    }

    .overlay-content {
        margin: 12px 0 0;
    }
}

// overlay color
.app-overlay {
    background: rgba(255,255,255,.9);
    a.overlay-close {
        color: $text-color;
    }
}





