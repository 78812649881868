// note: no img assets required for layout
// *{
//   text-transform: capitalize;
// }
html {
    height: 100%;
    background-color: $l_body_bg; // important! otherwise in "layout-boxed" mode, content > height 100% will
}

body {
    height: 100%;
    margin: 0;
    padding: 0;
    // background-color: $l_body_bg;
}

.full-height{
    height: 100% !important;
}



